import React from 'react';

import styles from './AccessDenied.module.css';
import labSvg from '../assets/images/laboratory.svg';

const AccessDenied: React.FC = () => (
  <div className={styles['access-denied']} data-e2e="accessDenied">
    <img
      src={labSvg}
      className={styles['access-denied__asset']}
      width="404"
      height="259"
      alt="Cortex"
    />
    <h2 className={styles['access-denied__title']}>Access Denied</h2>
    <p className={styles['access-denied__description']}>
      You don&apos;t have enough permissions to view this page.
    </p>
    <p className={styles['access-denied__description']}>
      Please check the link or contact support if you believe this is an error.
    </p>
  </div>
);

export default AccessDenied;
