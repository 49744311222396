import React from 'react';
import { Redirect } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { createInstance, Piral } from 'piral';

import Layout from './components/Layout';
import LoadingIndicator from './components/LoadingIndicator';
import AccessDenied from './pages/AccessDenied';
import NotFound from './pages/NotFound';
import ExtShellBreadcrumbs from './extensions/ExtShellBreadcrumbs';
import ExtShellLink from './extensions/ExtShellLink';
import ExtShellNavLink from './extensions/ExtShellNavLink';
import { createAuthApi, createConfigApi, createNavigationApi } from './plugins';

import './styles/main.css';
import './vendors';
import { ECortexRoute } from './configs';

const { KEYCLOAK, SHELL } = globalThis._CORTEX_CONFIG;
const urlSearchParams = new URLSearchParams(document.location.search);

const instance = createInstance({
  plugins: [
    createAuthApi({
      clientId: urlSearchParams.has(KEYCLOAK.LOGIN_SCREEN_PARAM)
        ? KEYCLOAK.CLIENT_ID_TEST
        : KEYCLOAK.CLIENT_ID,
      realm: KEYCLOAK.REALM,
      url: KEYCLOAK.URL,
    }),
    createConfigApi(),
    createNavigationApi(),
  ],
  state: {
    errorComponents: {
      not_found: NotFound,
    },
    components: {
      Layout,
      LoadingIndicator,
    },
    routes: {
      '/': () => <Redirect to={SHELL.REDIRECT_PATH} />,
    },
  },

  requestPilets() {
    return fetch(SHELL.MF_SERVICE_URL)
      .then((res) => res.json())
      .then((res) => {
        return res.items;
      });
  },
});

/**
 * Extensions
 */
instance.root.registerExtension('ext-shell-breadcrumbs', ({ params }) => (
  <ExtShellBreadcrumbs {...params} />
));
instance.root.registerExtension('ext-shell-link', ({ params }) => (
  <ExtShellLink {...params} />
));
instance.root.registerExtension('ext-shell-nav-link', ({ params }) => (
  <ExtShellNavLink {...params} />
));

/**
 * Pages
 */
instance.root.registerPage(ECortexRoute.AccessDenied, AccessDenied);
instance.root.registerPage(ECortexRoute.NotFound, NotFound);

const root = createRoot(document.querySelector('#cortex'));
root.render(<Piral instance={instance} />);
