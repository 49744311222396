import { PiralPlugin } from 'piral-core';
import { ECortexRoute } from '../configs';

interface INavigationPluginApi {
  cxNavigation: {
    onChange: (handler: () => void) => () => void;
    route: {
      getPath(): string;
      getUrl(): string;
      getSearchParam(paramName: string, searchParams?: string): string;
      getSubPath(level: number, path?: string): string;
    };
    router: {
      cortexPage(value: keyof typeof ECortexRoute): void;
      push(value: string): void;
      replace(value: string): void;
    };
  };
}

declare module 'piral-core/lib/types/custom' {
  interface PiletCustomApi extends INavigationPluginApi {}
}

export function createNavigationApi(): PiralPlugin<INavigationPluginApi> {
  return (context) => {
    return {
      cxNavigation: {
        onChange(handler: () => void) {
          return context.navigation.listen(handler);
        },

        route: {
          getPath() {
            return context.navigation.path;
          },

          getUrl() {
            return context.navigation.url;
          },

          getSearchParam(
            paramName: string,
            searchParams = document.location.search,
          ) {
            const urlSearchParams = new URLSearchParams(searchParams);
            return urlSearchParams.get(paramName);
          },

          getSubPath(level = 1, path = context.navigation.path) {
            return path?.split('/')[level + 1];
          },
        },

        router: {
          cortexPage(cortexRoute: keyof typeof ECortexRoute) {
            return context.navigation.push(ECortexRoute[cortexRoute]);
          },

          push(value: string) {
            return context.navigation.push(value);
          },

          replace(value: string) {
            return context.navigation.replace(value);
          },
        },
      },
    };
  };
}
