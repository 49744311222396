import React from 'react';

import styles from './NotFound.module.css';
import labSvg from '../assets/images/laboratory.svg';

const NotFound: React.FC = () => (
  <div className={styles['not-found']} data-e2e="notFound">
    <img
      src={labSvg}
      className={styles['not-found__asset']}
      width="404"
      height="259"
      alt="Cortex"
    />
    <h2 className={styles['not-found__title']}>Page not found</h2>
    <p className={styles['not-found__description']}>
      The page you&apos;re trying to access is unavailable.
    </p>
    <p className={styles['not-found__description']}>
      Please check the link or contact support if you believe this is an error.
    </p>
  </div>
);

export default NotFound;
